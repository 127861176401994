import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  h4 {
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
  }
`

export const SelectedCategoryButton = styled.button`
  margin-top: 4px;
  width: 100%;
  flex: 1;
  height: 48px;
  max-height: 48px;
  background-color: var(--background-black);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  text-align: left;

  display: flex;
  align-items: center;
  gap: 8px;

  &.none-selected {
    padding: 16px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`
