import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  background-color: var(--background-black);
  border-radius: 16px;

  h3 {
    font-size: 18px;
    line-height: 18px;
  }

  canvas {
    max-height: 200px !important;
  }

  .expenses-by-period {
    width: 100%;
    background-color: var(--background-black);
    padding: 16px;
    border-radius: 16px;
  }

  .loading-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
`
