import { HeaderPage } from '../../components/HeaderPage'
import { HeaderUser } from '../../components/HeaderUser'
import { LateralPanel } from './LateralPanel'
import { MainPanel } from './MainPanel'
import { Container, Content } from './styles'

export const Categories: React.FC = () => {
  return (
    <Container>
      <Content>
        <HeaderPage title="Suas categorias" />
        <HeaderUser />
        <MainPanel />
        <LateralPanel />
      </Content>
    </Container>
  )
}
