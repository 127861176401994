import * as icons from '@phosphor-icons/react'

import { type ICategory } from '../../types/ICategory'
import { Container } from './styles'

interface ICategoryIconProps {
  category: ICategory
}
const getIcon = (category: ICategory) => {
  if (category?.iconUrl?.startsWith('http')) {
    return <img src={category.iconUrl} alt={category.title} />
  }
  const iconName = category?.iconUrl ?? 'Prohibit'
  let Icon = icons.CurrencyDollar
  if (iconName && Object.keys((key: string) => key === iconName)) {
    Icon = icons[iconName as keyof typeof icons] as icons.Icon
  }

  return <Icon size={32} />
}
export const CategoryIcon: React.FC<ICategoryIconProps> = ({ category }) => {
  const icon = getIcon(category)

  return (
    <Container
      iconColor={category?.color ?? '#2A434D'}
      title={category?.title ?? 'Sem categoria'}
    >
      {icon}
    </Container>
  )
}
