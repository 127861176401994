import { useEffect, useState } from 'react'

import { Button } from '../../../../components/Button'
import { AddTransactionModal } from '../../../../components/Modals/AddTransactionModal'
import { Transaction } from '../../../../components/Transaction'
import { useChanges } from '../../../../hooks/ChangesContextProvider'
import { useModal } from '../../../../hooks/ModalContextProvider'
import { useWallet } from '../../../../hooks/WalletContextProvider'
import { api } from '../../../../services/api'
import { type ITransaction } from '../../../../types/ITransaction'
import { Container, Header, TransactionList } from './styles'

export const RecentTransactions: React.FC = () => {
  const [transactions, setTransactions] = useState<ITransaction[]>([])
  const { currentWallet } = useWallet()
  const { addModal, removeModal } = useModal()
  const { changes } = useChanges()

  const handleCloseAddTransactionModal = () => {
    removeModal('modal-add-transaction')
  }
  const handleOpenAddTransactionModal = () => {
    addModal({
      id: 'modal-add-transaction',
      content: <AddTransactionModal onClose={handleCloseAddTransactionModal} />,
    })
  }

  useEffect(() => {
    async function getTransactions() {
      const { data } = await api.get('/transactions', {
        params: {
          wallet_id: currentWallet?.id ?? undefined,
        },
      })
      setTransactions(data)
    }
    getTransactions().catch(console.error)
  }, [changes.transactions, currentWallet])

  return (
    <Container>
      <Header>
        <h3>Movimentações recentes</h3>
        <Button model="small" onClick={handleOpenAddTransactionModal}>
          Adicionar Movimentação
        </Button>
      </Header>

      <TransactionList>
        {transactions.map((transaction) => (
          <Transaction key={transaction.id} data={transaction} />
        ))}
        {!transactions.length && (
          <span className="empty">
            Está muito limpo por aqui, adoraria ver os meus gastos assim também!
          </span>
        )}
      </TransactionList>
    </Container>
  )
}
