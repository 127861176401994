import { type IModal } from '../../../types/IModal'
import { Container } from './styles'

interface IModalListProps {
  modals: IModal[]
  removeModal: (modalId: string) => void
}

export const ModalList: React.FC<IModalListProps> = ({
  modals,
  removeModal,
}) => {
  const handleClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    modal: IModal,
  ) => {
    const target = e.target as HTMLElement
    if (target?.classList?.contains(`shadow-${modal.id}`)) {
      removeModal(modal.id)
    }
  }

  return (
    <>
      {modals.map((modal, index) => (
        <Container
          key={modal.id}
          style={{ zIndex: `99${index}` }}
          className={`shadow-${modal.id}`}
          onClick={(e) => {
            handleClick(e, modal)
          }}
        >
          {modal.content}
        </Container>
      ))}
    </>
  )
}
