import { Overview } from './Overview'
import { RecentTransactions } from './RecentTransactions'
import { Container } from './styles'

export const MainPanel: React.FC = () => {
  return (
    <Container>
      <Overview />

      <RecentTransactions />
    </Container>
  )
}
