import { ChangesContextProvider } from './ChangesContextProvider'
import { LoginContextProvider } from './LoginContextProvider'
import { ModalContextProvider } from './ModalContextProvider'
import { PlanContextProvider } from './PlanContextProvider'
import { StyleContextProvider } from './StyleContextProvider'
import { ToastContextProvider } from './ToastContextProvider'
import { WalletContextProvider } from './WalletContextProvider'

interface IHookProps {
  children: React.ReactNode
}

export const Hooks: React.FC<IHookProps> = ({ children }) => {
  return (
    <>
      <ToastContextProvider>
        <LoginContextProvider>
          <PlanContextProvider>
            <ChangesContextProvider>
              <StyleContextProvider>
                <WalletContextProvider>
                  <ModalContextProvider>{children}</ModalContextProvider>
                </WalletContextProvider>
              </StyleContextProvider>
            </ChangesContextProvider>
          </PlanContextProvider>
        </LoginContextProvider>
      </ToastContextProvider>
    </>
  )
}
