import { Navigate } from 'react-router-dom'

import { useLogin } from '../../../hooks/LoginContextProvider'
import { Box, Container } from './styles'

export interface IAuthLayout {
  children: React.ReactNode
}

export const AuthLayout: React.FC<IAuthLayout> = ({ children }) => {
  const { isAuthenticated } = useLogin()

  if (isAuthenticated) {
    return <Navigate to="/" />
  }

  return (
    <Container>
      <img src="/logo.png" alt="Logo do Okaneko" />
      <Box>{children}</Box>
    </Container>
  )
}
