import { useEffect, useState } from 'react'

import { Plus, Prohibit } from '@phosphor-icons/react'

import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useModal } from '../../../hooks/ModalContextProvider'
import { api } from '../../../services/api'
import { getRecommendedCategories } from '../../../services/utils/getRecommendedCategories'
import { type ICategory } from '../../../types/ICategory'
import { CategoryIcon } from '../../CategoryIcon'
import { AddCategoryModal } from '../AddCategoryModal'
import {
  AddCategoryButton,
  CategoryItemButton,
  CategoryList,
  Container,
  NoneCategoryButton,
} from './styles'

interface IListCategoriesModalProps {
  preloadedCategories: ICategory[]
  onSelectCategory: (category: ICategory | null) => void
  type?: string | null
}

export const ListCategoriesModal: React.FC<IListCategoriesModalProps> = ({
  preloadedCategories,
  onSelectCategory,
  type,
}) => {
  const [userCategories, setUserCategories] = useState([...preloadedCategories])
  const recommendedCategories = getRecommendedCategories(userCategories)
  const { addModal, removeModal } = useModal()
  const { changes } = useChanges()

  const getUserCategories = async () => {
    const { data } = await api.get<ICategory[]>('/categories')

    setUserCategories(data)
  }

  const handleCloseModal = () => {
    getUserCategories().catch(console.error)

    removeModal('list-categories-add-category-modal')
  }

  const handleOpenAddCategoryModal = () => {
    addModal({
      id: 'list-categories-add-category-modal',
      content: <AddCategoryModal onClose={handleCloseModal} />,
    })
  }

  useEffect(() => {
    getUserCategories().catch(console.error)
  }, [changes.categories])

  return (
    <Container>
      <h3>Escolha uma Categoria</h3>
      <h4>Categorias do usuário</h4>
      <CategoryList>
        {userCategories.map((category) => (
          <CategoryItemButton
            key={category.id}
            type="button"
            title={category.title}
            onClick={() => {
              onSelectCategory(category)
            }}
          >
            <CategoryIcon category={category} />
            <p>{category.title}</p>
          </CategoryItemButton>
        ))}
        <NoneCategoryButton
          type="button"
          onClick={() => {
            onSelectCategory(null)
          }}
        >
          <Prohibit size={32} />
          <p>Nenhuma categoria</p>
        </NoneCategoryButton>
        <AddCategoryButton type="button" onClick={handleOpenAddCategoryModal}>
          <Plus size={32} />
          <p>Adicionar categoria</p>
        </AddCategoryButton>
      </CategoryList>
      <h4>Categorias recomendadas</h4>
      <CategoryList>
        {recommendedCategories
          .filter((category) => !category.type || category.type === type)
          .map((category) => (
            <CategoryItemButton
              key={category.id}
              type="button"
              title={category.title}
              onClick={() => {
                onSelectCategory(category)
              }}
            >
              <CategoryIcon category={category} />
              <p>{category.title}</p>
            </CategoryItemButton>
          ))}
      </CategoryList>
    </Container>
  )
}
