import styled from 'styled-components'

export const Container = styled.section`
  min-width: 56px;
  min-height: 56px;
  max-height: 56px;
  width: fit-content;
  position: relative;
  background-color: var(--background-black);
  border-radius: 32px;

  > button {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    color: var(--text-light);
    background: rgba(0, 0, 0, 0);
    border: none;
    min-width: 56px;
    min-height: 56px;
    width: fit-content;
    max-height: 56px;
    border-radius: 32px;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    padding: 0 10px 0 4px;

    .user-photo {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
      border: 4px solid var(--background-dark);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--background-darker);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p {
        font-weight: bold;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

export const OptionList = styled.ul`
  list-style: none;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  background-color: var(--background-dark);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  min-width: 100%;
  border-radius: 8px;
  overflow: auto;
  max-height: 0;
  z-index: 9;

  &.active {
    max-height: fit-content;
  }
`

export const OptionItem = styled.li`
  min-width: 100%;

  button {
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    min-width: 100%;
    padding: 8px 16px;
    background: rgba(0, 0, 0, 0);
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`
