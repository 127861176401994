const months = [
  { Name: 'Janeiro', name: 'janeiro', Min: 'Jan', min: 'jan' },
  { Name: 'Fevereiro', name: 'fevereiro', Min: 'Fev', min: 'fev' },
  { Name: 'Março', name: 'março', Min: 'Mar', min: 'mar' },
  { Name: 'Abril', name: 'abril', Min: 'Abr', min: 'abr' },
  { Name: 'Maio', name: 'maio', Min: 'Mai', min: 'mai' },
  { Name: 'Junho', name: 'junho', Min: 'Jun', min: 'jun' },
  { Name: 'Julho', name: 'julho', Min: 'Jul', min: 'jul' },
  { Name: 'Agosto', name: 'agosto', Min: 'Ago', min: 'ago' },
  { Name: 'Setembro', name: 'setembro', Min: 'Set', min: 'set' },
  { Name: 'Outubro', name: 'outubro', Min: 'Out', min: 'out' },
  { Name: 'Novembro', name: 'novembro', Min: 'Nov', min: 'nov' },
  { Name: 'Dezembro', name: 'dezembro', Min: 'Dez', min: 'dez' },
]

export const getAllMonths = (
  type: 'Name' | 'name' | 'min' | 'Min' = 'Name',
) => {
  return months.map((month) => month[type])
}
