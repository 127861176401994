import styled from 'styled-components'

export const Container = styled.article`
  padding: 16px;
  background-color: var(--background-black);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: min-content;
  position: relative;
`

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const HeaderButton = styled.button`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background-color: var(--background-darker);
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: var(--background-dark);
  }
`

export const DateButton = styled.button`
  height: 32px;
  padding: 0 16px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    background-color: var(--background-dark);
  }
`

export const Grid = styled.section`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(7, 32px);
`

export const GridItem = styled.button`
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border: none;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;

  &:hover {
    background: var(--background-darker);
  }
  &.other-month {
    opacity: 0.3;
  }
  &.today {
    background: var(--primary);
    color: var(--text-lighter);
    font-weight: 700;
  }
  &.selected {
    background: var(--green);
    color: var(--text-dark);
    opacity: 1;
    font-weight: 700;
  }
  &:hover {
    opacity: 1;
  }
  &.header {
    background: none;
    color: var(--text-lighter);
    opacity: 1;
    cursor: default;
    font-weight: 700;
  }
`

export const SelectYear = styled.section`
  position: absolute;
  top: 72px;
  bottom: 16px;
  left: 8px;
  right: 8px;
  z-index: 1;
  overflow: auto;

  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  padding: 8px;
  background-color: var(--background-black);
  border-radius: 16px;
`

export const YearOption = styled.button`
  flex: 1;
  min-width: 33%;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  height: 32px;
  background-color: var(--background-dark);

  &:hover {
    background-color: var(--background-darker);
  }
`
