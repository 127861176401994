import styled from 'styled-components'

export const Container = styled.article`
  padding: 16px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  gap: 16px;

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 8px;
    min-height: auto;
  }
`

interface ICategoryProps {
  color?: string | null
}

export const Category = styled.section<ICategoryProps>`
  width: 48px;
  height: 48px;
  color: var(--text-darker);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-darker);
`

export const Title = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  h4 {
    flex: 1;
    font-size: 20px;
    max-height: 48px;
    min-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    flex: 1;
    font-size: 12px;
    opacity: 0.4;
  }
`

export const Info = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 8px;

  .date {
    font-size: 14px;
    opacity: 0.4;
  }
  .money {
    font-size: 18px;
    font-weight: 700;
    color: #1dd3b0;

    &.negative {
      color: #e01e37;
    }
  }
`

export const Actions = styled.section`
  border-left: 1px solid #fff2;
  height: 100%;
  display: flex;
  gap: 16px;
  padding-left: 16px;

  @media (max-width: 460px) {
    border: none;
    padding: 0;
  }
`

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: none;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
`

interface IResponsiveLine {
  reverse?: boolean
}

export const ResponsiveLine = styled.section<IResponsiveLine>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  gap: 16px;
  overflow: hidden;
  min-height: 48px;
  max-height: 48px;

  @media (max-width: 460px) {
    width: 100%;
  }
`
