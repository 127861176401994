import styled from 'styled-components'

interface ICategoryItemProps {
  iconColor?: string | null
}
export const Container = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'iconColor',
})<ICategoryItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  color: ${(props) => props.iconColor ?? '#FF4A8F'};
`
