import { format } from 'date-fns'

import { PencilSimpleLine, Trash } from '@phosphor-icons/react'

import { parseMoney } from '../../services/utils/parseMoney'
import { type ITransaction } from '../../types/ITransaction'
import { CategoryIcon } from '../CategoryIcon'
import {
  Container,
  Category,
  Title,
  Info,
  Actions,
  ActionButton,
  ResponsiveLine,
} from './styles'

interface ITransactionProps {
  data: ITransaction
  onEdit?: () => void
  onDelete?: () => void
}

export const Transaction: React.FC<ITransactionProps> = ({
  data,
  onEdit,
  onDelete,
}) => {
  const date = format(new Date(data.paidIn), 'HH:mm dd/MM/yyyy')
  const money = parseMoney(data.type === 'INCOME' ? data.value : -data.value)

  return (
    <Container>
      <ResponsiveLine style={{ flex: 1 }}>
        <Category color={data.category?.color}>
          <CategoryIcon category={data.category} />
        </Category>
        <Title>
          <h4>{data.title}</h4>
          <p>{data.description}</p>
        </Title>
      </ResponsiveLine>
      <ResponsiveLine reverse>
        <Info>
          <div className="date">{date}</div>
          <div
            className={`money ${
              data.type === 'INCOME' ? 'positive' : 'negative'
            }`}
          >
            {money}
          </div>
        </Info>
        {(!!onEdit || !!onDelete) && (
          <Actions>
            {onEdit && (
              <ActionButton color="#3a86ff" onClick={onEdit}>
                <PencilSimpleLine size={24} />
              </ActionButton>
            )}
            {onDelete && (
              <ActionButton color="#e01e37" onClick={onDelete}>
                <Trash size={24} />
              </ActionButton>
            )}
          </Actions>
        )}
      </ResponsiveLine>
    </Container>
  )
}
