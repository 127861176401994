import styled from 'styled-components'

export const Container = styled.section`
  grid-area: panel-main;
  padding: 16px 16px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: 1248px) {
    padding: 0 14px 0 24px;
    overflow-y: unset;
    height: fit-content;
  }

  @media (max-width: 600px) {
    padding: 0 8px 0 0;
  }
`
