import styled, { css, keyframes } from 'styled-components'

import { darken, lighten } from 'polished'

export const Container = styled.section`
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: 0;
  max-width: 0;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  z-index: 99999;
`

const toastAnimation = keyframes`
  0% {
    left: -400px;
  }
  20% {
    left: 0px;
  }
  80% {
    left: 0px;
  }
  100% {
    left: -400px;
  }
`

interface IToast {
  toastType: 'success' | 'error' | 'info'
  time: number
}
export const Toast = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'toastType' && prop !== 'time',
})<IToast>`
  padding: 16px;
  width: 340px;
  max-width: 340px;
  border-radius: 16px;
  background-color: var(--background-light);
  color: var(--text-dark);
  border: 3px solid var(--text-dark);
  position: relative;
  overflow: hidden;
  animation: ${toastAnimation} ${(props) => props.time}s;
  left: -400px;

  h4 {
    margin-bottom: 8px;
  }
  p {
    opacity: 0.8;
  }

  .bar {
    background-color: var(--background-lighter);
    height: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .time {
      background-color: var(--text-light);
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  button {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff00;
    border: none;
    color: currentColor;
    border-radius: 8px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  ${(props) =>
    props.toastType === 'success' &&
    css`
      background-color: ${lighten(0.35, '#1dd3b0')};
      color: ${darken(0.2, '#1dd3b0')};
      border: 3px solid #1dd3b0;

      .bar {
        background-color: ${lighten(0.2, '#1dd3b0')};
        .time {
          background-color: ${darken(0.1, '#1dd3b0')};
        }
      }
    `}
  ${(props) =>
    props.toastType === 'error' &&
    css`
      background-color: ${lighten(0.35, '#e01e37')};
      color: ${darken(0.2, '#e01e37')};
      border: 3px solid #e01e37;

      .bar {
        background-color: ${lighten(0.2, '#e01e37')};
        .time {
          background-color: ${darken(0.1, '#e01e37')};
        }
      }
    `}
`

const timeBarAnimation = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`

interface ITimeBarProps {
  time: number
}
export const TimeBar = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'time',
})<ITimeBarProps>`
  width: 100%;
  animation: ${timeBarAnimation} ${(props) => props.time - 1}s linear;
`
