import { Link } from 'react-router-dom'

import styled from 'styled-components'

interface IContainerProps {
  useAd: boolean
}

export const Container = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'useAd',
})<IContainerProps>`
  display: grid;
  grid-template-areas: ${(props) => {
    return props.useAd ? "'menu content' 'menu footer'" : "'menu content'"
  }};
  grid-template-columns: 25.6rem 1fr;
  grid-template-rows: ${(props) => (props.useAd ? '1fr 80px' : '1fr')};
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;

  @media (max-width: 1640px) {
    grid-template-columns: 8rem 1fr;
  }
  @media (max-width: 600px) {
    grid-template-areas: ${(props) => {
      return props.useAd ? "'content' 'footer'" : "'content'"
    }};
    grid-template-columns: 1fr;
  }
`

export const Menu = styled.aside`
  grid-area: menu;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  max-height: 100vh;
  align-items: center;
  position: relative;
  transition: 0.2s;

  .spacer {
    display: block;
    flex: 1;
  }

  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 4px;
    font-size: 14px;
    line-height: 14px;
  }

  @media (max-width: 1640px) {
    .copyright {
      flex-direction: column;
      .year {
        font-size: 12px;
      }
    }
    .adsbygoogle {
      display: none !important;
    }
  }

  @media (max-width: 600px) {
    flex: 1;
    position: fixed;
    z-index: 9;
    top: 0;
    left: -100%;
    right: 100%;
    bottom: 0;
    background-color: var(--background-dark);

    &.open {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`

export const MenuContainer = styled.section`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  overflow: hidden;
  max-height: 100vh;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--background-dark);
  z-index: 9;
  transition: 0.2s;
  width: 100%;

  @media (min-width: 601px) {
    &.open {
      width: 300px;
    }
  }
  @media (max-width: 600px) {
    overflow: unset;
  }
`

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 8px;
  padding: 0 16px;
  width: 100%;

  @media (max-width: 600px) {
    flex: 2;
    justify-content: center;
  }
`

export const MenuItem = styled.li`
  display: block;
  height: 48px;
  color: var(--text-light);
  transition: 0.2s;
  overflow: hidden;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;

    .icon {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }
  }

  &.active {
    background-color: var(--background-darker);
    border-radius: 8px;
    color: var(--text-primary);
  }

  &:hover {
    background-color: var(--background-darker);
    border-radius: 8px;
    color: var(--text-lighter);
  }

  @media (max-width: 600px) {
    height: 56px;

    a {
      height: 56px;
    }
  }
`

export const Logo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0;

  img.complete-logo {
    display: block;
    height: 64px;
  }
  img.icon-logo {
    height: 48px;
    width: 48px;
    display: none;
  }

  @media (max-width: 1640px) {
    img.complete-logo {
      display: none;
    }
    img.icon-logo {
      display: block;
    }
  }
  @media (max-width: 600px) {
    img.complete-logo {
      display: block;
    }
    img.icon-logo {
      display: none;
    }
  }
`

export const MenuHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  height: 56px;
  width: 100%;
  padding: 0;

  @media (max-width: 1640px) {
    grid-template-columns: 8rem 1fr;
    justify-content: center;
  }
  @media (max-width: 600px) {
    justify-content: space-between;
    width: 100%;
  }

  &.open {
    width: 100%;
    padding: 0 16px;
    justify-content: space-between;
    margin-bottom: 96px;
  }
`

export const Content = styled.main`
  grid-area: content;
  background-color: var(--background-darker);
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.2s;

  @media (max-width: 600px) {
    border-radius: 0;
  }
`

export const Footer = styled.footer`
  grid-area: footer;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (max-width: 1680px) {
    .adsense + .adsense {
      display: none !important;
    }
  }
`

export const MenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 56px;
  min-width: 56px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: var(--background-darker);
  }

  @media (min-width: 1641px) {
    display: none;
  }

  @media (max-width: 600px) {
    position: absolute;
    top: 32px;
    left: calc(100% + 8px);
    z-index: 9;
    border-radius: 50%;

    &:hover {
      background-color: var(--background-black);
    }
  }
`

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: var(--background-darker);
  }

  @media (min-width: 1641px) {
    display: none;
  }
`
