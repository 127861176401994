import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { ForgotPassword } from '../pages/Auth/ForgotPassword'
import { Login } from '../pages/Auth/Login'
import { RecoverPassword } from '../pages/Auth/RecoverPassword'
import { SignUp } from '../pages/Auth/SignUp'
import { Categories } from '../pages/Categories'
import { Home } from '../pages/Home'
import { Transactions } from '../pages/Transactions'
import { AppLayout } from '../styles/layouts/AppLayout'
import { AuthLayout } from '../styles/layouts/AuthLayout'

export const Routes: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <AppLayout>
          <Home />
        </AppLayout>
      ),
    },
    {
      path: '/transactions',
      element: (
        <AppLayout>
          <Transactions />
        </AppLayout>
      ),
    },
    {
      path: '/categories',
      element: (
        <AppLayout>
          <Categories />
        </AppLayout>
      ),
    },
    {
      path: '/login',
      element: (
        <AuthLayout>
          <Login />
        </AuthLayout>
      ),
    },
    {
      path: '/signup',
      element: (
        <AuthLayout>
          <SignUp />
        </AuthLayout>
      ),
    },
    {
      path: '/forgot-password',
      element: (
        <AuthLayout>
          <ForgotPassword />
        </AuthLayout>
      ),
    },
    {
      path: '/recover-password',
      element: (
        <AuthLayout>
          <RecoverPassword />
        </AuthLayout>
      ),
    },
  ])

  return <RouterProvider router={router} />
}
