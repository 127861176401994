import { createContext, useState, useEffect, useContext } from 'react'

import jwtDecoder from 'jwt-decode'

import { type IPlanType } from '../../types/IPlanType'
import { useLogin } from '../LoginContextProvider'

interface IPlanContextProvider {
  children: React.ReactNode
}

interface ICreateContext {
  plan: IPlanType
  useAd: boolean
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const PlanContext = createContext({} as ICreateContext)

const typesToShowAd: IPlanType[] = ['FREE']

export const PlanContextProvider: React.FC<IPlanContextProvider> = ({
  children,
}) => {
  const [plan, setPlan] = useState<IPlanType>(() => {
    const token = localStorage.getItem('@okaneko/token')
    if (token) {
      const planType = jwtDecoder<{ role: IPlanType }>(token).role
      if (planType) {
        return planType
      }
    }
    return 'FREE'
  })
  const [useAd, setUseAd] = useState(
    () => !plan || typesToShowAd.some((t) => t === plan),
  )
  const { token } = useLogin()

  useEffect(() => {
    if (token) {
      const planType = jwtDecoder<{ role: IPlanType }>(token).role
      if (planType) {
        setUseAd(typesToShowAd.some((t) => t === planType))
        setPlan(planType)
      }
    }
  }, [token])

  return (
    <PlanContext.Provider value={{ plan, useAd }}>
      {children}
    </PlanContext.Provider>
  )
}

export const usePlan = () => {
  const context = useContext(PlanContext)

  if (!context) {
    throw new Error('Theres a problem with PlanContext')
  }

  return context
}
