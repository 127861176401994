import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'

import jwtDecoder from 'jwt-decode'

import { api } from '../../services/api'
import { type IUserLogin } from '../../types/IUserLogin'
import { useToast } from '../ToastContextProvider'

interface ILoginContextProviderProps {
  children: React.ReactNode
}

interface ICreateContext {
  token: string | null
  user: IUserLogin | null
  isAuthenticated: boolean
  handleLogin: (email: string, password: string) => Promise<void>
  handleLogout: () => void
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const LoginContext = createContext({} as ICreateContext)

function getUser() {
  const userStr = localStorage.getItem('@okaneko/user')
  if (!userStr) return null
  const user = JSON.parse(userStr) as IUserLogin
  const splitedName = user.name.split(' ')

  user.firstName = splitedName[0]
  user.lastName = splitedName[splitedName.length - 1]
  return user
}
function getToken() {
  return localStorage.getItem('@okaneko/token')
}
export const LoginContextProvider: React.FC<ILoginContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<IUserLogin | null>(getUser)
  const [token, setToken] = useState<string | null>(getToken)
  const [isAuthenticated, setIsAuthenticated] = useState(!!token)
  const { addToast } = useToast()

  function saveUser(user: IUserLogin | null) {
    if (!user) {
      setUser(null)
      localStorage.removeItem('@okaneko/user')
      return
    }
    const splitedName = user.name.split(' ')

    user.firstName = splitedName[0]
    user.lastName = splitedName[splitedName.length - 1]

    setUser(user)
    localStorage.setItem('@okaneko/user', JSON.stringify(user))
  }
  function saveToken(token: string | null) {
    if (!token) {
      setToken(null)
      setIsAuthenticated(false)
      localStorage.removeItem('@okaneko/token')
      return
    }

    setToken(token)
    localStorage.setItem('@okaneko/token', token)
    setIsAuthenticated(true)
  }

  async function handleLogin(email: string, password: string) {
    const { data } = await api.post('/auth/login', { email, password })

    saveUser(data.user)
    saveToken(data.accessToken)
  }

  const handleLogout = useCallback(() => {
    saveUser(null)
    saveToken(null)
  }, [])

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined
    if (token) {
      setIsAuthenticated(true)

      api.defaults.headers.Authorization = `Bearer ${token}`

      if (
        jwtDecoder<{ exp: number }>(token).exp <= Math.floor(Date.now() / 1000)
      ) {
        handleLogout()
      } else {
        interval = setInterval(() => {
          if (
            jwtDecoder<{ exp: number }>(token).exp <=
            Math.floor(Date.now() / 1000)
          ) {
            clearInterval(interval)
            addToast({
              title: 'Seu login expirou.',
              description:
                'Ops! O seu login expirou e sua conta foi desconectada. Por favor, realize entre novamente.',
              type: 'error',
              time: 5,
            })
            handleLogout()
          }
        }, 5000)
      }
    }

    return () => {
      clearInterval(interval)
    }
  }, [addToast, handleLogout, token])

  return (
    <LoginContext.Provider
      value={{ isAuthenticated, token, handleLogin, handleLogout, user }}
    >
      {children}
    </LoginContext.Provider>
  )
}

export const useLogin = () => {
  const context = useContext(LoginContext)

  if (!context) {
    throw new Error('Theres a problem with LoginContext')
  }

  return context
}
