import styled from 'styled-components'

export const Container = styled.section`
  position: absolute;
  background-color: var(--background-darker);
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 573px;

  h3 {
    margin-bottom: 8px;
  }
`

export const AddCategoryButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  min-width: 127px;
  max-width: 127px;
  height: 48px;
  cursor: pointer;
  padding: 8px;
  line-height: 16px;
  text-align: center;

  background-color: var(--background-black);
  border-radius: 8px;

  &:hover {
    filter: brightness(0.8);
  }
`

export const NoneCategoryButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  min-width: 127px;
  max-width: 127px;
  height: 48px;
  cursor: pointer;
  padding: 8px;
  line-height: 16px;
  text-align: center;

  background-color: var(--background-black);
  border-radius: 8px;

  &:hover {
    filter: brightness(0.8);
  }
`

export const CategoryList = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 11px;

  & + h4 {
    margin-top: 16px;
  }
`

export const CategoryItemButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  min-width: 127px;
  max-width: 127px;
  height: 48px;
  cursor: pointer;
  padding: 0 8px 0 0;
  text-align: left;

  background-color: var(--background-black);
  border-radius: 8px;

  p {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    filter: brightness(0.8);
  }
`
