import styled from 'styled-components'

export const Container = styled.div`
  padding: 8px;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  gap: 16px;

  .icon {
    background-color: var(--background-darker);
    border-radius: 16px;
  }

  .category-info {
    flex: 1;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 8px;
    min-height: auto;
  }
`

export const Actions = styled.section`
  border-left: 1px solid #fff2;
  height: 100%;
  display: flex;
  gap: 16px;
  padding-left: 16px;

  @media (max-width: 460px) {
    border: none;
    padding: 0;
  }
`

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: none;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
`
