import styled from 'styled-components'

export const Container = styled.header`
  grid-area: header-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  padding: 0 16px 0 32px;
  gap: 8px;

  @media (max-width: 1248px) {
    padding: 32px 32px 0;
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    z-index: 1;
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
    color: var(--text-light);

    span {
      color: var(--text-lighter);
    }
  }

  @media (max-width: 600px) {
    padding: 32px 8px 0;
  }

  @media (max-width: 380px) {
    flex-direction: column;
    align-items: start;
  }
`
