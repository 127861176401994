import { GlobalStyle } from '../../styles/global'
import { myTheme } from '../../styles/theme'
import { usePlan } from '../PlanContextProvider'

interface IStyleHook {
  children: React.ReactNode
}
export const StyleContextProvider: React.FC<IStyleHook> = ({ children }) => {
  const { useAd } = usePlan()
  return (
    <>
      <GlobalStyle theme={{ ...myTheme, useAd }} />
      {children}
    </>
  )
}
