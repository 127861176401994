import styled from 'styled-components'

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  img {
    display: block;
    max-width: 250px;
    margin-bottom: 32px;
  }
`

export const Box = styled.section`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  background-color: var(--background-start);
  padding: 32px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  margin-bottom: 64px;
`
