import { useMemo } from 'react'

import { useLogin } from '../../hooks/LoginContextProvider'
import { ChangeWallet } from '../Inputs/ChangeWallet'
import { Container } from './styles'

interface IHeaderPageProps {
  title?: string
}

export const HeaderPage: React.FC<IHeaderPageProps> = ({ title }) => {
  const { user } = useLogin()
  const message = useMemo(() => {
    const date = new Date()
    const hours = date.getHours()

    if (hours >= 7 && hours < 12) {
      return ', tenha um bom dia! ☀️'
    }
    if (hours >= 12 && hours < 18) {
      return ', tenha uma boa tarde! ☀️'
    }
    if (hours >= 18 && hours <= 23) {
      return ', tenha uma boa noite! 🌔'
    }
    if (hours >= 0 && hours < 7) {
      return ', tenha uma boa madrugada! 🌔'
    }
    return '.'
  }, [])

  return (
    <Container>
      <h2>
        {title ?? (
          <>
            Olá <span>{user?.firstName}</span>
            {message}
          </>
        )}
      </h2>

      <ChangeWallet />
    </Container>
  )
}
