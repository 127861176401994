import styled from 'styled-components'

export const Container = styled.article`
  flex: 1;
  padding: 16px 8px 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1248px) {
    padding-top: 160px;
  }
  @media (max-width: 720px) {
    padding-top: 176px;
  }
`

export const Content = styled.section`
  flex: 1;
  display: grid;
  grid-template-areas: 'header-content header-user' 'panel-main panel-lateral';
  grid-template-columns: 1fr 490px;
  grid-template-rows: 72px 1fr;
  overflow: hidden;
  font-family: var(--font-inter);

  @media (max-width: 1248px) {
    grid-template-areas: 'panel-main' 'panel-lateral';
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
    overflow-y: auto;
    height: fit-content;
  }
`
