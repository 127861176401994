import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { CaretDown } from '@phosphor-icons/react'

import { useWallet } from '../../../hooks/WalletContextProvider'
import { type IWallet } from '../../../types/IWallet'
import { Container, WalletList, WalletItem } from './styles'

interface IWalletSelectProps {
  name: string
  label: string
  required?: boolean
}

export const WalletSelect: React.FC<IWalletSelectProps> = ({
  name,
  label,
  required,
}) => {
  const [wallet, setWallet] = useState<IWallet | null>(null)
  const [isListActive, setIsListActive] = useState(false)
  const { register, setValue, getValues } = useFormContext()
  const { wallets, currentWallet } = useWallet()

  const handleChangeWallet = (wallet: IWallet) => {
    setValue(name, wallet.id)
    setWallet(wallet)
  }

  useEffect(() => {
    const walletId = getValues(name) || currentWallet?.id || wallets[0]?.id
    const newWallet = wallets.find((item) => item.id === walletId)
    if (newWallet) {
      setWallet(newWallet)
      setValue(name, newWallet.id)
    }
  }, [currentWallet?.id, getValues, name, setValue, wallets])

  return (
    <Container>
      <p>
        <strong>
          {label} {required && '*'}
        </strong>
      </p>
      <button
        type="button"
        onFocus={() => {
          setIsListActive(true)
        }}
        onBlur={() => {
          setIsListActive(false)
        }}
      >
        {wallet?.name}
        <CaretDown size={24} />

        <input type="hidden" {...register(name)} />
      </button>
      <WalletList className={isListActive ? 'active' : ''}>
        {wallets.map((wallet) => (
          <WalletItem key={wallet.id}>
            <button
              type="button"
              onMouseDown={() => {
                handleChangeWallet(wallet)
              }}
            >
              {wallet.name}
            </button>
          </WalletItem>
        ))}
      </WalletList>
    </Container>
  )
}
