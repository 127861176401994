import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Button } from '../../../components/Button'
import { Input } from '../../../components/Inputs/Input'
import { api } from '../../../services/api'
import { Container, Title } from './styles'

interface IFormData {
  email: string
}

export const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [sendend, setIsSended] = useState(false)
  const methods = useForm<IFormData>()

  async function handleSubmit(data: IFormData) {
    setLoading(true)
    await api.post('/accounts/forgot-password', data)
    setLoading(false)
    setIsSended(true)
  }

  return (
    <Container>
      <Title>Recuperar Senha</Title>
      {!sendend && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Input
              name="email"
              label="E-mail"
              placeholder="Digite o seu e-mail"
            />
            <Button type="submit" loading={loading}>
              Recuperar Senha
            </Button>
          </form>
        </FormProvider>
      )}
      {sendend && (
        <span className="success">
          Se este email estiver cadastrado no Okaneko, um email de recuperação
          será enviado.
        </span>
      )}
      <Link to="/signup">
        Não tem uma conta? <u>Criar uma</u>
      </Link>
      <Link to="/login">
        Lembrou a senha? <u>Entrar</u>
      </Link>
    </Container>
  )
}
