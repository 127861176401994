import { createContext, useState, useEffect, useContext } from 'react'

import { api } from '../../services/api'
import { type IWallet } from '../../types/IWallet'
import { useChanges } from '../ChangesContextProvider'
import { useLogin } from '../LoginContextProvider'

interface IWalletContextProvider {
  children: React.ReactNode
}

interface ICreateContext {
  currentWallet: IWallet | null
  wallets: IWallet[]
  changeWallet: (id: string) => void
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const WalletContext = createContext({} as ICreateContext)

function getWallets() {
  const userStr = localStorage.getItem('@okaneko/user')
  if (!userStr) return []
  return JSON.parse(userStr).wallets || []
}

export const WalletContextProvider: React.FC<IWalletContextProvider> = ({
  children,
}) => {
  const [wallets, setWallets] = useState<IWallet[]>(getWallets)
  const [currentWallet, setCurrentWallet] = useState<IWallet | null>(
    wallets[0] || null,
  )
  const { user } = useLogin()
  const { changes } = useChanges()

  function changeWallet(id: string) {
    const wallet = wallets.find((wallet) => wallet.id === id)
    if (!wallet) return

    setCurrentWallet(wallet)
  }

  useEffect(() => {
    setWallets(user?.wallets ?? [])
  }, [user?.wallets])

  useEffect(() => {
    if (wallets.length > 0) {
      if (!currentWallet) {
        setCurrentWallet(wallets[0])
      } else if (!wallets.some((wallet) => wallet.id === currentWallet.id)) {
        setCurrentWallet(wallets[0])
      }
    }
  }, [currentWallet, wallets])

  useEffect(() => {
    async function getWallets() {
      const response = await api.get<IWallet[]>('/wallets')
      setWallets(response.data)
      const userStr = localStorage.getItem('@okaneko/user')
      if (userStr) {
        const userData = JSON.parse(userStr)
        userData.wallets = response.data
        localStorage.setItem('@okaneko/user', JSON.stringify(userData))
      }
    }
    getWallets().catch(console.error)
  }, [changes.wallets])

  return (
    <WalletContext.Provider value={{ wallets, currentWallet, changeWallet }}>
      {children}
    </WalletContext.Provider>
  )
}

export const useWallet = () => {
  const context = useContext(WalletContext)

  if (!context) {
    throw new Error('Theres a problem with WalletContext')
  }

  return context
}
