import { type ICategory } from '../../types/ICategory'

export const getRecommendedCategories = (
  userCategories: ICategory[],
): ICategory[] => {
  const categories: ICategory[] = [
    {
      id: '01',
      title: 'Salário',
      iconUrl: 'Money',
      color: '#06d6a0',
    },
    {
      id: '02',
      title: 'Moradia',
      iconUrl: 'HouseLine',
      color: '#ffd166',
    },
    {
      id: '03',
      title: 'Supermercado',
      iconUrl: 'ShoppingCart',
      color: '#ef476f',
    },
    {
      id: '04',
      title: 'TV / Internet / Telefone',
      iconUrl: 'WifiHigh',
      color: '#118ab2',
    },
    {
      id: '05',
      title: 'Transporte',
      iconUrl: 'CarProfile',
      color: '#073b4c',
    },
    {
      id: '06',
      title: 'Saúde',
      iconUrl: 'FirstAidKit',
      color: '#9b5de5',
    },
    {
      id: '07',
      title: 'Lazer',
      iconUrl: 'Park',
      color: '#f15bb5',
    },
    {
      id: '08',
      title: 'Bares e restaurantes',
      iconUrl: 'ForkKnife',
      color: '#fee440',
    },
    {
      id: '09',
      title: 'Lanche',
      iconUrl: 'Hamburger',
      color: '#00bbf9',
    },
    {
      id: '10',
      title: 'Roupas novas',
      iconUrl: 'TShirt',
      color: '#00f5d4',
    },
    {
      id: '11',
      title: 'Salão de Beleza / Barbearia',
      iconUrl: 'Scissors',
      color: '#ef476f',
    },
    {
      id: '12',
      title: 'Happy hour',
      iconUrl: 'BeerBottle',
      color: '#ffd166',
    },
    {
      id: '13',
      title: 'Cinema',
      iconUrl: 'FilmSlate',
      color: '#06d6a0',
    },
    {
      id: '14',
      title: 'Baladas',
      iconUrl: 'Martini',
      color: '#118ab2',
    },
  ]

  return categories.filter(
    (rCat) => !userCategories.some((uCat) => uCat.title === rCat.title),
  )
}
