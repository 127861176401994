import { Hooks } from './hooks'
import { Routes } from './routes'

export const App: React.FC = () => {
  return (
    <Hooks>
      <Routes />
    </Hooks>
  )
}
