import styled from 'styled-components'

export const Container = styled.section`
  min-width: 56px;
  min-height: 56px;
  max-width: 56px;
  max-height: 56px;
  position: relative;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-black);
    color: var(--text-light);
    border: none;
    min-width: 56px;
    min-height: 56px;
    max-width: 56px;
    max-height: 56px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transition: 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
`

export const NotificationUl = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  background-color: var(--background-dark);
  border-radius: 8px;
  overflow: auto;
  max-height: 0;
  z-index: 999;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  &.active {
    max-height: 300px;
  }

  .empty {
    width: 300px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 16px;
  }
`

export const NotificationItem = styled.li`
  display: flex;
  width: 300px;
  position: relative;

  .info-button {
    flex: 1;
    display: flex;
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.2s;

    img {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 50%;
      border: 2px solid var(--background-dark);
    }

    p {
      display: flex;
      align-items: center;
      text-align: left;
      flex: 1;
      min-height: 32px;
      padding-right: 48px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    border: none;
    background: none;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`
