import styled, { keyframes } from 'styled-components'

const ellipsis1Animation = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
  `
const ellipsis2Animation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
  `
const ellipsis3Animation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const containerAnimation = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 78px;
  }
`

export const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  animation: ${containerAnimation} 0.5s;
  overflow: hidden;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 78px;
    height: 40px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 14px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ${ellipsis1Animation} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ${ellipsis2Animation} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ${ellipsis2Animation} 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ${ellipsis3Animation} 0.6s infinite;
  }
`
