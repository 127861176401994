import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  a {
    margin-top: 8px;
  }

  .success {
    color: var(--green);
  }
`

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 32px;
`
