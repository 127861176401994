import * as Icons from '@phosphor-icons/react'

import { type IIconsName } from '../../../../services/utils/getRecommendedIcons'

interface IIconProps {
  name: IIconsName
}

export const Icon: React.FC<IIconProps> = ({ name }) => {
  const SearchedIcon = Icons[name] as Icons.Icon
  let defIcon = <Icons.Money size={32} />
  if (SearchedIcon) {
    defIcon = <SearchedIcon size={32} />
  }
  return <>{defIcon}</>
}
