import styled from 'styled-components'

export const Container = styled.section`
  grid-area: panel-lateral;
  padding: 16px 32px 0 16px;
  overflow-x: hidden;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .calendar-row {
      display: flex;
      justify-content: ${(props) =>
        props.theme.useAd ? 'space-between' : 'center'};
      gap: 8px;
    }
  }

  .chart {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 100%;
  }

  @media (max-width: 1248px) {
    overflow-y: unset;
    height: fit-content;
    padding: 16px 16px 0 16px;
  }

  @media (max-width: 600px) {
    padding: 16px 8px 0 0;
  }
`
