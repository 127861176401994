import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Button } from '../../../components/Button'
import { Input } from '../../../components/Inputs/Input'
import { useLogin } from '../../../hooks/LoginContextProvider'
import { Container, Title } from './styles'

interface IFormData {
  email: string
  password: string
}

export const Login: React.FC = () => {
  const methods = useForm<IFormData>()
  const { handleLogin } = useLogin()

  async function handleSubmit(data: IFormData) {
    try {
      await handleLogin(data.email, data.password)
    } catch {
      methods.setError('password', {
        type: 'validate',
        message: 'Nome ou senha incorretos.',
      })
    }
  }

  return (
    <Container>
      <Title>Entrar</Title>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Input
            name="email"
            label="E-mail"
            placeholder="Digite o seu e-mail"
          />
          <Input
            name="password"
            label="Senha"
            type="password"
            placeholder="Digite a sua senha"
          />
          <Button type="submit">Entrar</Button>
        </form>
        <Link to="/signup">
          Não tem uma conta? <u>Criar uma</u>
        </Link>
        <Link to="/forgot-password">
          Esqueceu a senha? <u>Recuperar senha</u>
        </Link>
      </FormProvider>
    </Container>
  )
}
