import styled from 'styled-components'

export const Container = styled.section`
  position: relative;

  .color-picker {
    position: absolute;
    z-index: 1;
    background-color: var(--background-dark) !important;

    label {
      color: var(--text-light) !important;
    }

    input {
      background-color: var(--background-darker) !important;
      box-shadow: 0 0 0 1px var(--background-black) !important;
    }
  }
`

interface IPreviewButtonProps {
  color: string
}
export const PreviewButton = styled.button.attrs<IPreviewButtonProps>(
  (props) => ({
    style: { background: props.color },
  }),
)`
  height: 48px;
  width: 48px;
  cursor: pointer;
  border-radius: 8px;
  border: 3px solid var(--background-black);
  margin-top: 4px;
`
