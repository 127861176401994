import styled from 'styled-components'

export const Container = styled.section`
  grid-area: panel-main;
  padding: 0 8px 16px 32px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .transaction-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
    overflow: auto;
    padding-right: 8px;

    .empty {
      flex: 1;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
    }
  }

  @media (max-width: 1248px) {
    padding: 0 8px 16px 24px;

    .transaction-list {
      max-height: calc(100vh - 400px);
    }
  }

  @media (max-width: 600px) {
    padding: 0 8px 16px 0;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-right: 8px;

  @media (max-width: 460px) {
    flex-direction: column;
    padding: 0;
    text-align: center;
    gap: 8px;
  }
`
