import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 90%;
  max-width: 500px;
  padding: 32px;
  background-color: var(--background-dark);
  border-radius: 16px;
`

export const Row = styled.section`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Button = styled.button`
  flex: 1;
  padding: 16px;
  border-radius: 8px;
  border: none;
  background-color: ${(props) => props.color};
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  opacity: 0.7;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`
