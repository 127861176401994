import styled from 'styled-components'

export const Container = styled.header`
  grid-area: header-user;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  width: 100%;
  padding: 0 32px 0 16px;

  @media (max-width: 1248px) {
    padding: 32px 32px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .col {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;

    @media (max-width: 600px) {
      padding-left: 64px;
    }
  }

  @media (max-width: 600px) {
    padding: 32px 8px 0;
  }
`
