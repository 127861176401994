import styled from 'styled-components'

export const Container = styled.form`
  background-color: var(--background-darker);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 500px;
  padding: 16px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Row = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CloseButton = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background-color: var(--background-darker);
  cursor: pointer;

  &:hover {
    background-color: var(--background-black);
  }
`
