import { useEffect, useState } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'

import { House, CurrencyDollar, X, List, Stack } from '@phosphor-icons/react'

import { Adsense } from '../../../components/Adsense'
import { useLogin } from '../../../hooks/LoginContextProvider'
import { usePlan } from '../../../hooks/PlanContextProvider'
import {
  Container,
  Content,
  Footer,
  Logo,
  Menu,
  MenuItem,
  MenuList,
  CloseButton,
  MenuButton,
  MenuContainer,
  MenuHeader,
} from './styles'

export interface IAuthLayout {
  children: React.ReactNode
}

export const AppLayout: React.FC<IAuthLayout> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { isAuthenticated } = useLogin()
  const { pathname } = useLocation()
  const { useAd } = usePlan()

  useEffect(() => {
    setIsMenuOpen(false)
  }, [pathname])

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  const year = `2023${
    new Date().getFullYear() !== 2023 ? ` - ${new Date().getFullYear()}` : ''
  }`

  return (
    <Container useAd={useAd} className={isMenuOpen ? 'open' : ''}>
      <Menu className={isMenuOpen ? 'open' : ''}>
        <MenuContainer className={isMenuOpen ? 'open' : ''}>
          {!isMenuOpen && (
            <MenuButton
              onClick={() => {
                setIsMenuOpen(true)
              }}
            >
              <List size={32} />
            </MenuButton>
          )}
          <MenuHeader className={isMenuOpen ? 'open' : ''}>
            {isMenuOpen && (
              <CloseButton
                onClick={() => {
                  setIsMenuOpen(false)
                }}
              >
                <X size={32} />
              </CloseButton>
            )}
            <Logo to="/">
              <img
                className="complete-logo"
                src="/complete-logo-white.png"
                alt="Logo branca completa do Okaneko"
              />
              <img
                className="icon-logo"
                src="/icon-logo-white.png"
                alt="Logo em ícone branca do Okaneko"
              />
            </Logo>
          </MenuHeader>
          <MenuList>
            <MenuItem className={pathname === '/' ? 'active' : ''}>
              <Link to="/">
                <div className="icon">
                  <House size={24} />
                </div>
                Visão geral
              </Link>
            </MenuItem>
            <MenuItem className={pathname === '/transactions' ? 'active' : ''}>
              <Link to="/transactions">
                <div className="icon">
                  <CurrencyDollar size={24} />
                </div>
                Movimentações
              </Link>
            </MenuItem>
            <MenuItem className={pathname === '/categories' ? 'active' : ''}>
              <Link to="/categories">
                <div className="icon">
                  <Stack size={24} />
                </div>
                Categorias
              </Link>
            </MenuItem>
            {/* <MenuItem className={pathname === '/dashboards' ? 'active' : ''}>
              <Link to="/dashboards">
                <div className="icon">
                  <SquaresFour size={24} />
                </div>
                Painéis
              </Link>
            </MenuItem> */}
          </MenuList>

          {useAd && (
            <>
              <span className="spacer"></span>
              <Adsense
                adSlot="6037858024"
                format="fixed"
                size={{ x: '224px', y: '224px' }}
              />
              <span className="spacer"></span>
              <Adsense
                adSlot="6037858024"
                format="fixed"
                size={{ x: '224px', y: '224px' }}
              />
            </>
          )}
          <span className="spacer"></span>
          <span className="copyright">
            <span>Okaneko</span>
            <span>&copy;</span>
            <span className="year">{year}</span>
          </span>
        </MenuContainer>
      </Menu>

      <Content>{children}</Content>

      {useAd && (
        <Footer>
          <Adsense
            adSlot="4237970438"
            format="fixed"
            size={{ y: '80px', x: '720px' }}
          />
          <Adsense
            adSlot="4237970438"
            format="fixed"
            size={{ y: '80px', x: '720px' }}
          />
        </Footer>
      )}
    </Container>
  )
}
