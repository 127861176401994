import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-size: 16px;
  }

  input {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: none;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: 4px;
    color: var(--text-light);

    &::placeholder {
      color: currentColor;
      opacity: 0.4;
    }
  }

  .error {
    display: block;
    margin-top: 4px;
    color: var(--red);
  }
`
