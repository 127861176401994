import styled, { css } from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 20px;
    font-weight: 700;
  }
`

export const Row = styled.section`
  display: flex;
  gap: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

interface IBoxProps {
  type?: 'income' | 'outcome' | 'balance'
}

export const Box = styled.section<IBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  font-size: 16px;
  color: var(--text-lighter);
  position: relative;
  min-height: 160px;
  padding: 32px;
  background: linear-gradient(90deg, #000000, #2e2e2e);
  border-radius: 16px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  h4 {
    position: absolute;
    top: 16px;
    left: 16px;
    font-weight: 400;
  }

  .row {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    flex-wrap: wrap;
  }

  .money-item {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    position: relative;
    height: 74px;

    .money {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      font-size: 40px;
      font-weight: 700;
      height: 49px;
      min-width: 100px;
      text-align: right;
      white-space: nowrap;

      &.negative {
        color: #e01e37;
      }

      @media (max-width: 400px) {
        font-size: 32px;
      }
      @media (max-width: 320px) {
        font-size: 24px;
      }
    }

    .info {
      font-size: 12px;
      font-weight: 400;
      opacity: 0.7;
      height: 16px;
      bottom: 0;
      right: 0;
      position: absolute;
    }

    &.small {
      .money {
        font-size: 18px;
      }
    }
    &.normal {
      .money {
        font-size: 24px;
      }
    }
    &.medium {
      .money {
        font-size: 32px;

        @media (max-width: 320px) {
          font-size: 24px;
        }
      }
    }

    & + .money-item {
      margin-left: 64px;
    }

    & + .small {
      margin-left: 32px;
    }
  }

  ${(props) =>
    props.type === 'income' &&
    css`
      background: linear-gradient(90deg, #086375, #1dd3b0);
    `}
  ${(props) =>
    props.type === 'outcome' &&
    css`
      background: linear-gradient(90deg, #880d1e, #e01e37);
    `}
`
