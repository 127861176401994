import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { X } from '@phosphor-icons/react'

import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { api } from '../../../services/api'
import { Button } from '../../Button'
import { Input } from '../../Inputs/Input'
import { InputColor } from '../../Inputs/InputColor'
import { InputIcon } from '../../Inputs/InputIcon'
import { InputSelect } from '../../Inputs/InputSelect'
import { CloseButton, Container, Header, Row } from './styles'

interface IAddCategoryModalProps {
  onClose: () => void
}

interface IFormData {
  title: string
  color: string
  iconUrl: string
  description?: string
  type?: '' | 'INCOME' | 'OUTCOME'
}

export const AddCategoryModal: React.FC<IAddCategoryModalProps> = ({
  onClose,
}) => {
  const { handleChange } = useChanges()
  const [loadingAddCategory, setLoadingAddCategory] = useState(false)
  const { addToast } = useToast()
  const methods = useForm<IFormData>({
    defaultValues: {
      title: '',
      description: '',
      color: '#FF4A8F',
      iconUrl: 'Money',
      type: '',
    },
  })

  const submit = async (data: IFormData) => {
    setLoadingAddCategory(true)

    try {
      if (!data.description) {
        data.description = undefined
      }
      if (!data.type) {
        data.type = undefined
      }
      await api.post('/categories', data)
      handleChange('categories')
      addToast({
        title: 'Sucesso!',
        description: 'A categoria foi inserida com sucesso!',
        type: 'success',
        time: 3,
      })
      setLoadingAddCategory(false)
      onClose()
    } catch {
      addToast({
        title: 'Oops...',
        description: 'Parece que houve um problema ao inserir a categoria...',
        type: 'error',
        time: 5,
      })
      setLoadingAddCategory(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <Container onSubmit={methods.handleSubmit(submit)}>
        <Header>
          <h3>Adicionar categoria</h3>
          <CloseButton onClick={onClose}>
            <X size={32} />
          </CloseButton>
        </Header>
        <Row>
          <InputColor name="color" label="Escolha uma cor" />
          <InputIcon name="iconUrl" label="Escolha um ícone" align="right" />
        </Row>
        <Input
          name="title"
          label="Título"
          placeholder="Dê um nome pra categoria!"
          required
        />
        <Input name="description" label="Descrição" placeholder="Descreva!" />
        <InputSelect
          name="type"
          label="Tipo de movimentação da categoria"
          placeholder="É uma categoria para entradas ou saídas?"
          options={[
            { label: 'Entradas e Saídas', value: '' },
            { label: 'Saídas', value: 'OUTCOME' },
            { label: 'Entradas', value: 'INCOME' },
          ]}
        />
        <Button type="submit" loading={loadingAddCategory}>
          Criar Categoria
        </Button>
      </Container>
    </FormProvider>
  )
}
