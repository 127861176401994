import { useState, useEffect } from 'react'

import { useChanges } from '../../../../hooks/ChangesContextProvider'
import { useWallet } from '../../../../hooks/WalletContextProvider'
import { api } from '../../../../services/api'
import { parseMoney } from '../../../../services/utils/parseMoney'
import { type IBalance } from '../../../../types/IBalance'
import { OverviewTypeSelect } from './OverviewTypeSelect'
import { Container, Header, Row, Box } from './styles'

function getMoneyClasses(value: number | undefined) {
  const classes = ['money']
  if (value && value < 0) {
    classes.push('negative')
  }
  return classes.join(' ')
}

export const Overview: React.FC = () => {
  const [overviewType, setOverviewType] = useState('monthly')
  const [balance, setBalance] = useState<IBalance | null>(null)
  const { currentWallet } = useWallet()
  const { changes } = useChanges()

  useEffect(() => {
    async function getBalance() {
      const { data } = await api.get<IBalance>('/balance/overview', {
        params: {
          type: overviewType || 'monthly',
          wallet_id: currentWallet?.id ?? undefined,
        },
      })

      data.overview.incomeFormatted = parseMoney(data.overview.income)
      data.overview.outcomeFormatted = parseMoney(
        data.overview.outcome > 0
          ? -data.overview.outcome
          : data.overview.outcome,
      )
      data.balance.dateFormatted = parseMoney(data.balance.date)
      data.balance.weekFormatted = parseMoney(data.balance.week)
      data.balance.monthFormatted = parseMoney(data.balance.month)
      data.balance.totalFormatted = parseMoney(data.balance.total)
      setBalance(data)
    }

    getBalance().catch(console.error)
  }, [overviewType, changes.transactions, currentWallet])

  return (
    <Container>
      <Header>
        <h3>Visão Geral</h3>

        <OverviewTypeSelect value={overviewType} setValue={setOverviewType} />
      </Header>
      <Row>
        <Box type="outcome">
          <h4>Saídas</h4>
          <div className="row">
            <div className="money-item">
              <p className="money">{balance?.overview.outcomeFormatted}</p>
              <p className="info">Este mês</p>
            </div>
          </div>
        </Box>
        <Box type="income">
          <h4>Entradas</h4>
          <div className="row">
            <div className="money-item">
              <p className="money">{balance?.overview.incomeFormatted}</p>
              <p className="info">Este mês</p>
            </div>
          </div>
        </Box>
      </Row>
      <Box type="balance">
        <h4>Saldo</h4>
        <div className="row">
          <div className="money-item small">
            <p className={getMoneyClasses(balance?.balance.date)}>
              {balance?.balance.dateFormatted}
            </p>
            <p className="info">Hoje</p>
          </div>
          <div className="money-item small">
            <p className={getMoneyClasses(balance?.balance.week)}>
              {balance?.balance.weekFormatted}
            </p>
            <p className="info">Esta semana</p>
          </div>
          <div className="money-item normal">
            <p className={getMoneyClasses(balance?.balance.month)}>
              {balance?.balance.monthFormatted}
            </p>
            <p className="info">Este mês</p>
          </div>
          <div className="money-item medium">
            <p className={getMoneyClasses(balance?.balance.total)}>
              {balance?.balance.totalFormatted}
            </p>
            <p className="info">Total</p>
          </div>
        </div>
      </Box>
    </Container>
  )
}
