import styled from 'styled-components'

export const Container = styled.aside`
  grid-area: panel-lateral;
  padding: 0 24px 16px 16px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1248px) {
    padding: 0 8px 16px 24px;
  }

  @media (max-width: 600px) {
    padding: 0 0 16px;
  }
`

export const Row = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-right: 8px;

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 8px;
  }
`

export const WalletList = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 500px;
  overflow: auto;
  padding-right: 8px;
`

export const WalletItem = styled.article`
  display: flex;
  padding: 0 8px 0 16px;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  background-color: var(--background-black);

  .money {
    &.red {
      color: #e01e37;
    }
    &.green {
      color: #1dd3b0;
    }
  }

  h4 {
    flex: 1;
  }

  .actions {
    display: flex;
    gap: 8px;
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid #fff2;

    @media (max-width: 460px) {
      padding-left: 0;
      border: none;
    }
  }

  @media (max-width: 460px) {
    flex-direction: column;
    height: fit-content;
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 8px;
  }
`

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: none;
  background-color: ${(props) => props.color};
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
`
