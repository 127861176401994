import styled from 'styled-components'

interface IContainerProps {
  align: 'left' | 'center' | 'right'
}
export const Container = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'align',
})<IContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: ${(props) =>
    props.align.replace('left', 'flex-start').replace('right', 'flex-end')};
`

export const PreviewIconButton = styled.button`
  width: 48px;
  height: 48px;
  background-color: var(--background-black);
  border: none;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const IconsList = styled.section`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  max-height: 300px;
  z-index: 9;
  overflow: hidden auto;
  background-color: var(--background-dark);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;
`

export const SelectIconButton = styled.button`
  width: 48px;
  height: 48px;
  background-color: var(--background-dark);
  border: none;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--background-darker);
  }
`
