import styled from 'styled-components'

export const Container = styled.section`
  flex: 1;
  padding: 16px 8px 16px 16px;
  background-color: var(--background-black);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 700px;
`

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 16px 16px;

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 8px;
    text-align: center;
  }
`

export const TransactionList = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  padding-right: 8px;

  .empty {
    flex: 1;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
  }
`
