import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 90%;
  max-width: 500px;
  padding: 32px;
  background-color: var(--background-dark);
  border-radius: 16px;
`
