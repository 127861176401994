'use client'

import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  :root{
    font-size: 62.5%;
    --background-light: #b3c5e8;
    --background-lighter: #ffffff;
    --background-dark: #292C44;
    --background-darker: #232639;
    --background-black: #1C1E2E;

    --text-light: #7F8EAB;
    --text-lighter: #FFFFFF;
    --text-dark: #1C1E2E;
    --text-darker: #000000;
    --text-primary: #FF4A8F;

    --primary: #FF4A8F;
    --primary-dark: #b23364;

    --green: #1dd3b0;
    --red: #e01e37;

    --background-start: #292C44;
    --background-end: #232639;
    --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
      'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
      'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
    --font-nunito: 'Nunito', Arial, Helvetica, sans-serif;
    --font-inter: 'Inter', Arial, Helvetica, sans-serif;
  }
      
  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;

   color-scheme: dark;
  }

  body {
    min-height: 100vh;
    background: linear-gradient(
        to bottom,
        transparent,
        var(--background-end)
      )
      var(--background-start);
  }

  body, button, input, select, textarea {
    color: var(--text-light);
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (prefers-color-scheme: dark) {
    html {
      color-scheme: dark;
    }
  }

  /* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #7F8EAB #DFE9EB;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(0,0,0,0.2);
}

*::-webkit-scrollbar-track:hover {
  background-color: rgba(0,0,0,0.2);
}

*::-webkit-scrollbar-track:active {
  background-color: rgba(0,0,0,0.2);
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color:  #7F8EAB;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #4b5569;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #4b5569;
}

iframe html {
  background: #000000 !important;
}
`
