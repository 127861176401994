import { useState, useEffect } from 'react'

import { AxiosError } from 'axios'

import { PencilSimpleLine, Trash } from '@phosphor-icons/react'

import { Button } from '../../../components/Button'
import { AddWalletModal } from '../../../components/Modals/AddWalletModal'
import { YesNoModal } from '../../../components/Modals/YesNoModal'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useModal } from '../../../hooks/ModalContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { api } from '../../../services/api'
import { parseMoney } from '../../../services/utils/parseMoney'
import { type IWallet } from '../../../types/IWallet'
import {
  ActionButton,
  Container,
  Header,
  Row,
  WalletItem,
  WalletList,
} from './styles'

export const LateralPanel: React.FC = () => {
  const [wallets, setWallets] = useState<IWallet[]>([])
  const { addModal, removeModal } = useModal()
  const { addToast } = useToast()
  const { changes, handleChange } = useChanges()

  const handleAddWallet = () => {
    addModal({
      content: (
        <AddWalletModal
          onClose={() => {
            removeModal('add-wallet-modal')
          }}
        />
      ),
      id: 'add-wallet-modal',
    })
  }

  const handleEdit = async (walletId: string) => {
    console.log(walletId)
    handleChange('wallets')
  }

  const handleDelete = async (walletId: string) => {
    try {
      await api.delete(`/wallets/${walletId}`)
      handleChange('wallets')

      addToast({
        title: 'Sucesso!',
        description: 'A carteira foi removida com sucesso!',
        type: 'info',
        time: 5,
      })
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 400) {
        addToast({
          title: 'Ops!',
          description: e.response.data.messageCode || e.response.data.message,
          type: 'error',
          time: 5,
        })
      } else {
        addToast({
          title: 'Ops!',
          description: 'Aconteceu algum problema ao remover a carteira...',
          type: 'error',
          time: 5,
        })
      }
    }
  }

  const requestDelete = (wallet: IWallet) => {
    addModal({
      content: (
        <YesNoModal
          title="Confirme a deleção."
          description={`Dejesa realmente deletar a carteira ${wallet.name}? Ao confirmar, todas as movimentações dessa carteira também serão deletadas.`}
          onClose={() => {
            removeModal('request-delete-wallet')
          }}
          onYes={() => {
            handleDelete(wallet?.id).catch(console.error)
          }}
        />
      ),
      id: 'request-delete-wallet',
    })
  }

  useEffect(() => {
    async function getWallets() {
      const response = await api.get<IWallet[]>('/wallets', {
        params: { with_balance: true },
      })
      setWallets(response.data)
    }

    getWallets().catch(console.error)
  }, [changes.wallets, changes.transactions])

  return (
    <Container>
      <Row>
        <Header>
          <h3>Carteiras</h3>
          <Button model="small" onClick={handleAddWallet}>
            Adicionar Carteira
          </Button>
        </Header>
        <WalletList>
          {wallets.map((wallet) => (
            <WalletItem key={wallet.id}>
              <h4>{wallet.name}</h4>
              <p
                className={
                  (wallet?.balance ?? 0) > 0
                    ? 'money green'
                    : (wallet?.balance ?? 0) < 0
                    ? 'money red'
                    : 'money'
                }
              >
                {parseMoney(wallet?.balance ?? 0)}
              </p>
              <div className="actions">
                <ActionButton
                  color="#3a86ff"
                  onClick={() => {
                    handleEdit(wallet.id).catch(console.error)
                  }}
                >
                  <PencilSimpleLine size={16} />
                </ActionButton>
                <ActionButton
                  color="#e01e37"
                  onClick={() => {
                    requestDelete(wallet)
                  }}
                >
                  <Trash size={16} />
                </ActionButton>
              </div>
            </WalletItem>
          ))}
        </WalletList>
      </Row>
    </Container>
  )
}
