import { FormProvider, useForm } from 'react-hook-form'

import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { api } from '../../../services/api'
import { Button } from '../../Button'
import { Input } from '../../Inputs/Input'
import { ModalHeader } from '../ModalHeader'
import { Container } from './styles'

interface IAddWalletModalProps {
  onClose: () => void
}

interface IFormData {
  name: string
}

export const AddWalletModal: React.FC<IAddWalletModalProps> = ({ onClose }) => {
  const methods = useForm<IFormData>()
  const { addToast } = useToast()
  const { handleChange } = useChanges()

  const handleSubmit = async (data: IFormData) => {
    try {
      await api.post('/wallets', data)
      addToast({
        title: 'Sucesso!',
        description: 'Carteira adicionada com sucesso!',
        type: 'success',
        time: 5,
      })
      handleChange('wallets')

      onClose()
    } catch {}
  }

  return (
    <FormProvider {...methods}>
      <Container onSubmit={methods.handleSubmit(handleSubmit)}>
        <ModalHeader title="Adicionar Carteira" onClose={onClose} />
        <Input label="Nome" name="name" placeholder="O nome da sua carteira." />
        <Button type="submit">Adicionar Carteira</Button>
      </Container>
    </FormProvider>
  )
}
