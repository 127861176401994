import { useState } from 'react'

import { CaretDown } from '@phosphor-icons/react'

import { Container, WalletList, WalletItem } from './styles'

interface IOverviewTypeSelectProps {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
}

const options = [
  {
    label: 'Diário',
    value: 'daily',
  },
  {
    label: 'Semanal',
    value: 'weekly',
  },
  {
    label: 'Mensal',
    value: 'monthly',
  },
  {
    label: 'Trimestral',
    value: 'quarterly',
  },
  {
    label: 'Semestral',
    value: 'semiannually',
  },
  {
    label: 'Anual',
    value: 'annually',
  },
  {
    label: 'Total',
    value: 'total',
  },
]
export const OverviewTypeSelect: React.FC<IOverviewTypeSelectProps> = ({
  value,
  setValue,
}) => {
  const [isListActive, setIsListActive] = useState(false)

  return (
    <Container>
      <button
        type="button"
        onFocus={() => {
          setIsListActive(true)
        }}
        onBlur={() => {
          setIsListActive(false)
        }}
      >
        {options.find((item) => item.value === value)?.label}
        <CaretDown size={24} />
      </button>
      <WalletList className={isListActive ? 'active' : ''}>
        {options.map((option) => (
          <WalletItem key={option.value}>
            <button
              type="button"
              onMouseDown={() => {
                setValue(option.value)
              }}
            >
              {option.label}
            </button>
          </WalletItem>
        ))}
      </WalletList>
    </Container>
  )
}
